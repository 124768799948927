<template>
  <div class="animated fadeIn container">
    <h1>3사 수신여부를 확인할 수 있습니다</h1>
    <h2 class="mt-2 color-skyblue">{{ availableSendCount }}건 발송가능</h2>
    <div class="row mt-4">
      <div class="col-4">
        <div class="phone-wrap">
          <textarea
            class="inner-text"
            id="message"
            name="body"
            rows="4"
            v-model="message"
            placeholder="메시지를 입력해주세요"
            v-on:input="updateMessage"
            required
            @keydown.enter.shift.exact.prevent="createSmsRequest"
          ></textarea>
          <span class="color-flatwhite inner-bytes">{{ contentBytes + 1 }}/70</span>
        </div>
        <b-btn ref="sendBtn" variant="primary" class="send-btn" @click="createSmsRequest" :disabled="isLoading">
          <span v-if="!isLoading">3사 테스트 발송</span>
          <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
        </b-btn>
      </div>
      <div class="col-8">
        <table class="border-table request-table mt-20" v-show="list.length > 0">
          <thead>
            <tr>
              <td v-show="$store.state.user.level == 9" class="username">이름</td>
              <td class="date">발송시각</td>
              <td class="message">내용</td>
              <td class="message-list">상태</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td v-show="$store.state.user.level == 9" class="username">{{ item.user.username }}</td>
              <td class="date">{{ item.createdAt | dateFormatWithNewLine }}</td>
              <td class="message">{{ item.message }}</td>
              <td class="company-list">
                <div>
                  SKT
                  <span class="status-label success" v-if="item.skt == 1">성공</span>
                  <span class="status-label warning" v-else>대기</span>
                </div>
                <div>
                  KT
                  <span class="status-label success" v-if="item.kt == 1">성공</span>
                  <span class="status-label warning" v-else>대기</span>
                </div>
                <div>
                  LG
                  <span class="status-label success" v-if="item.lg == 1">성공</span>
                  <span class="status-label warning" v-else>대기</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="border-container" v-show="isInit && list.length === 0">
          <div class="empty-view">
            3사 테스트 발송 내역이 없습니다
          </div>
        </div>
        <b-btn variant="danger" class="float-right clear-btn" @click="deleteAllRequests" :disabled="isLoading">
          <span v-if="!isLoading">발송내역 삭제</span>
          <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
        </b-btn>
      </div>
    </div>
    <b-modal ref="sendCompleteModal" ok-only ok-title="확인" centered>메시지 전송 완료</b-modal>
  </div>
</template>
<script>
import SmsTestService from '@/services/SmsTestService'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import { mapGetters } from 'vuex'
import { Datetime } from 'vue-datetime'
import { Settings } from 'luxon'

Settings.defaultLocale = 'kr'

export default {
  name: 'SmsSend',
  data() {
    return {
      isInit: false,
      availableSendCount: 0,
      message: '',
      isLoading: false,
      loaderStyle: {
        color: 'rgba(255, 255, 255, 0.7)',
        size: '8px',
      },
      list: [],
      timer: null,
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.remove('bg-white')
    this.getUserInfo()

    const t = this
    t.getRequests()
    this.timer = setInterval(function() {
      t.getRequests()
    }, 6000)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  components: {
    PulseLoader,
    Datetime,
  },
  methods: {
    createSmsRequest() {
      if (this.message.length < 0) {
        alert('메시지를 입력해주세요')
        return
      } else if (this.message.length > 69) {
        alert('메시지는 70자까지만 가능합니다')
        return
      }

      if (this.isLoading) {
        return
      }
      this.isLoading = true

      const t = this
      SmsTestService.createSmsRequest({ message: this.message })
        .then(function(response) {
          if (response.status !== 200) {
            return
          }
          t.getUserInfo()
          t.getRequests()
          t.$refs.sendCompleteModal.show()
          setTimeout(function() {
            t.isLoading = false
          }, 200)
        })
        .catch(function() {
          t.isLoading = false
        })
    },
    getRequests() {
      const t = this
      SmsTestService.getRequests().then(response => {
        if (response.status !== 200) {
          return
        }
        const { list = [] } = response.data
        t.list = list
        t.isInit = true
      })
    },
    updateMessage(e) {
      this.message = e.target.value
    },
    async deleteAllRequests() {
      if (!confirm('삭제하시겠습니까?')) {
        return
      }
      const response = await SmsTestService.delete()
      if (response.status == 200) {
        alert('성공')
        this.getRequests()
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    contentBytes: function() {
      var message = this.message
      return message.length
    },
  },
  watch: {
    user() {
      this.availableSendCount = parseInt(this.user.money / this.user.smsFee)
    },
    list() {},
    isLoading() {
      this.$refs.sendBtn.disabled = this.isLoading
    },
  },
}
</script>

<style scoped lang="scss">
.border-container {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.empty-view {
  line-height: 300px;
  text-align: center;
}

#sms-send-loader {
  display: block;
  margin: 20px auto;
}

.phone-wrap {
  position: relative;
  width: 256px;
  height: 523px;
  background: url('/img/phone_test.png') center no-repeat;
}

.phone-wrap .inner-text {
  position: absolute;
  top: 65px;
  left: 18px;
  width: 220px;
  bottom: 65px;
  padding: 20px 15px;
  background: transparent;
  color: white;
  line-height: 20px;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  border: none;
  letter-spacing: 1;
}

.phone-wrap .inner-text::placeholder {
  color: #999;
  font-weight: 500;
}

.phone-wrap .inner-bytes {
  position: absolute;
  right: 33px;
  bottom: 73px;
}

.send-btn {
  display: block;
  margin-top: 30px;
  margin-left: 60px;
  height: 40px;
  border-radius: 0.25rem;
  padding: 0 20px;
}

.clear-btn {
  margin-top: 20px;
  float: right;
  height: 40px;
  border-radius: 0.25rem;
  padding: 0 20px;
}

#server-status-sub-msg {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;
  white-space: nowrap;
  letter-spacing: -0.2px;
}

.request-table {
  margin-top: -20px;
  border-bottom: 1px solid #000;

  tbody {
    > tr {
      border-top: 1px solid #d0d0d0;
    }

    > td {
      min-height: 70px;
    }
  }

  thead td {
    text-align: center;
  }

  .username {
    min-width: 100px;
  }

  .date {
    min-width: 160px;
    line-height: 20px;
    white-space: pre;
    text-align: center;
  }

  .message {
    width: 100%;
    word-break: break-all;
    line-height: 20px;
  }

  .company-list {
    min-width: 300px;
    line-height: 20px;
    text-align: center;

    > div {
      display: inline-block;
      padding: 0 8px;
    }

    .status-label {
      padding: 3px 10px;
      font-size: 10px;
      border-radius: 2px;
      color: white;

      &.success {
        background-color: #6cba7b;
      }

      &.warning {
        background-color: #f6c244;
      }
    }
  }
}
</style>
